$bodyFont: 'Noto Sans Lao', sans-serif;
$displayFont: 'Libre Baskerille', serif;


body {
  background-image: url("/assets/img/snowBkg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;

  color: #595959;
  font-family: $bodyFont;
}
.site-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.container {
  @include xy-grid-container();
}
.footer, .header {
  flex-shrink: 0;
}
.main {
  flex: 1 0 auto;
  margin-top: rem-calc(0);
  @include xy-grid-container();
  @include breakpoint(large) {
    margin-top: rem-calc(204);
  }
}
a {
  color: #595959;
}
a:hover {
  color: #d8ad48;
}
section.header {
//  position: fixed;
//  top: 0;
//  left: 0;
  width: 100%;
  @include breakpoint(large) {
    position: fixed;
    top: 0;
    left: 0;
  }
  div.topBar {
    background-color: white;
    div.logoNameContainer {
      @include xy-grid-container();
    //  @include xy-grid();
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      padding: rem-calc(5 10 5 0);
      div.logoBar {
        @include xy-cell(7);
       }
      div.nameBar {
        flex-grow: 1;
        h1 {
          margin: rem-calc(0 0 0 0);
          padding: rem-calc(0 0 0 0);
          font-family: $displayFont;
          font-size: rem-calc(17);
          line-height: 1;
          text-align: right;
          @include breakpoint(medium) {
            font-size: rem-calc(28);
          }
        }
        h2 {
          text-align: right;
          font-size: rem-calc(13);
          margin: rem-calc(0 0 4 0);
          padding: rem-calc(0);
        }
      }
    }
  }
  section.navigation {
    display: flex;
    @include flex-align(center, middle);
    background-color: #ffffff99;
    border-bottom: 1px solid #ecbf4e;
    border-top: 1px solid #ecbf4e;
    ul.menu {
      @include flex-align($x: center);
      li {
        padding: rem-calc(0);
        margin: rem-calc(0);
        text-transform: uppercase;
        font-size: rem-calc(12);
        font-weight: 600;
        a {
          color: #595959;
        }
        a:hover {
          color: #d8ad48;
        }
      }
    }
  }
}

section.footer {
  background-color: white;
  //display: flex;
//  @include flex-align(center, middle);
  border-top: 1px solid #ecbf4e;
  padding: rem-calc(10 0);
  div.contact {
    text-align: center;
    font-size: rem-calc(14);
  }
  ul.socialLinks {
    list-style-type: none;
    display: flex;
    @include flex-align($x: center);
    li a img {
      width: rem-calc(24);
      height: rem-calc(24);
      @include breakpoint(large) {
        width: rem-calc(28);
        height: rem-calc(28);
      }
    }
  }
  div.copyright {
    @include xy-cell(12);
    p {
      text-align: center;
      font-size: rem-calc(12);
    }
  }
}
section.mainShop {
  padding: rem-calc(14 0 0 0);
  div.intro {
    padding: rem-calc(10 0 0 0);
    h2 {
      text-align: center;
      font-size: rem-calc(30);
      font-family: $displayFont;
    }
    p {
      text-align: center;
      font-size: rem-calc(14);
      padding: rem-calc(0 20);
    }
    @include breakpoint(large) {
      padding: rem-calc(30 0 0 0);
    }
  }
  div.shopListings {
    @include xy-grid();
    padding: rem-calc(0 0 20 0);
    div.shopItem {
      @include xy-cell(12);
      border: 1px solid #ffffff;
      background-color: #00000020;
      margin: rem-calc(10);
      display: flex;
      flex-direction: column;
      @include breakpoint(medium) {
        @include xy-cell(6);
      }
      div.itemImg {
        flex-grow: 1;
        display: flex;
        @include flex-align(center, middle);
        padding: rem-calc(20 10 10 10);
        img.vertical {
          width: 85%;
        }
      }
      div.itemTitle {
        background-color: #ffffff90;
        flex-shrink: 1;
        margin: rem-calc(10 0 0 0);
        p {
          text-align: center;
          font-size: rem-calc(13);
          margin: rem-calc(0);
          padding: rem-calc(4 0 4 0);
          font-weight: 500;
          line-height: 1;
          span {
            font-weight: 300;
          }
        }
      }
    }
  }
  div.shopLink {
    @include xy-cell(12);
    margin: rem-calc(20 0);
    div.shopLinkIcon {
      text-align: center;
      img {
        width: 50%;
        @include breakpoint(medium) {
          width: 30%;
        }
        @include breakpoint(large) {
          width: 20%;
        }
      }
    }
  }
}
section.aboutContact {
  padding: rem-calc(40 0 20 0);
}
